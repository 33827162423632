<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  // globalData: {
  // 		verno: 'v2.0.0.1.060801',
  // 		fullheight: 0,
  // 		dxweixinid: "43424351528",
  // 		dxtokent: '0f7fa6389a744079a3a4fd38d7d93d6f',
  // 		mchno: 0,
  // 		shopid: '',
  // svrURL: "http://localhost:42875/api/wineapi.ashx",
  // svrURL: "http://testapi.dxerp.net/api/rptapi.ashx",
  // svrURL: "http://test.91erp.net/api/rptapi.ashx",
  //svrURL: "https://jbapi.dxerp.net/api/rptapi.ashx",
  // 		mchInfo: null
  // 	},
  methods: {
    navclick: function (item) {
      console.log(item);
      this.$router.push({
        path: item.href,
      });
    },
  },
};
</script>
<style>
.mainapp {
  display: flex;
  flex-direction: row;
}
.mainapp .menu {
  width: 100px;
}
.mainapp .main {
  flex-grow: 1;
}
.mainapp .head {
  margin-top: 100px;
}
.mainapp .head div {
  float: left;
  margin-right: 10px;
  background: lightcoral;
}
/* 在全局CSS里引用: */
/* 滚动条宽度 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

/*edit*/
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
}
a{
	 text-decoration: none;
}
html,
body {
 margin:0;
 padding:0;
}
.foot {
  color: #8c8c8c;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: fixed ;
  bottom: 0;
  left: 0;
}
.el-slider__button{
	width: 10px;
	height: 10px;
}
/* .el-slider{
	width:300px !important;
} */
</style>
