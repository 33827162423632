import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
  state: {
    systemuser: {},
    agentuser: {},
    customeruser: {},
    designeruser: {},
    stateTagsList:[]
  },
  mutations: {
    // 登录
 //    systemloginin(state, systemuser) {
 //      state.systemuser = systemuser;
 //      sessionStorage.setItem("systemuser", JSON.stringify(systemuser));
 //    },
 //    // 退出
 //    systemloginout(state, user) {
 //      state.user = "";
 //      sessionStorage.setItem("systemuser", "");
 //    },
 //    customerloginin(state, customeruser) {
 //      state.customeruser = customeruser;
 //      sessionStorage.setItem("customeruser", JSON.stringify(customeruser));
 //    },
 //    customerloginout(state, customeruser) {
 //      state.customeruser = "";
 //      sessionStorage.setItem("customeruser", "");
 //    }, 
	// designerloginin(state, designeruser) {
 //      state.designeruser = designeruser;
 //      sessionStorage.setItem("designeruser", JSON.stringify(designeruser));
 //    },
 //    designerloginout(state, designeruser) {
 //      state.designeruser = "";
 //      sessionStorage.setItem("designeruser", "");
 //    }, 
	// sysloginin(state, customeruser) {
 //      state.customeruser = customeruser;
 //      sessionStorage.setItem("systemuser", JSON.stringify(customeruser));
 //    },
 //    sysloginout(state, customeruser) {
 //      state.customeruser = "";
 //      sessionStorage.setItem("systemuser", "");
 //    },
    /**
     * 选择tag标签
     */
    mutationSelectTags(state, data) {
      let result = false
      for (let i = 0; i < state.stateTagsList.length; i++) {
        if (state.stateTagsList[i].url == data.url) {
          return result = true
        }
      }
      console.log(state,'statestate');
      result === false ? state.stateTagsList.push(data) : ''
    },

    /**
     * 关闭tag标签
     */
    mutationCloseTag(state, data) {
      let result = state.stateTagsList.findIndex(item => item.url === data.url)
      state.stateTagsList.splice(result, 1)
    }
  }
})
