function genID() {
	//return Number(Math.random().toString().substr(3,20) + Date.now()).toString(36);
	return (Math.random() * 10000000).toString(16).substr(0, 4) + (new Date()).getTime() + Math.random().toString()
		.substr(2, 5);

}
/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp) => {
	let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	let Y = date.getFullYear() + '-'
	let M =
		(date.getMonth() + 1 < 10 ?
			'0' + (date.getMonth() + 1) :
			date.getMonth() + 1) + '-'
	let D =
		(date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	let h =
		(date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	let m =
		(date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
		':'
	let s =
		date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return Y + M + D + h + m + s
};

function timestampToDate(timestamp) {
	let date = new Date(parseInt(timestamp) * 1000);
	let year = date.getFullYear();
	let month = date.getMonth() + 1; // 月份从0开始，需要加1
	let day = date.getDate();
	let hours = date.getHours();
	let minutes = date.getMinutes();
	let seconds = date.getSeconds();
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
/**
 * 存储localStorage
 */
const setStore = (name, content) => {
	if (!name) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
const getStore = name => {
	if (!name) return;
	return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
const removeStore = name => {
	if (!name) return;
	window.localStorage.removeItem(name);
}

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
	let date = new Date();
	date.setDate(date.getDate() + day);
	document.cookie = name + '=' + value + ';expires=' + date;
};

/**
 * 获取cookie
 **/
function getCookie(name) {
	let reg = RegExp(name + '=([^;]+)');
	let arr = document.cookie.match(reg);
	if (arr) {
		return arr[1];
	} else {
		return '';
	}
};

/**
 * 删除cookie
 **/
function delCookie(name) {
	setCookie(name, null, -1);
};

function getBase64(file, width = 500, size = 5) { //把图片转成base64编码
	return new Promise(function(resolve, reject) {
		const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
		const isLt2M = file.size / 1024 / 1024 < size
		if (!isJPG) {
			reject('头像图片必须是JPG格式！');
			return
		}
		if (!isLt2M) {

			reject('头像图片大小不能超过5MB！');
			return
		}


		let reader = new FileReader();
		let imgResult = "";
		reader.readAsDataURL(file);
		reader.onload = function() {
			//imgResult = reader.result;
			imgResult = compress(reader.result, width, 0.8)
		};
		reader.onerror = function(error) {
			reject(error);
		};
		reader.onloadend = function() {
			resolve(imgResult);
		}
	})
}
function getloginuser() {
	let _u = localStorage.getItem("loginuser")
	if (_u == undefined) {
		return null;
	}

	let u = JSON.parse(_u);
	if (u == undefined) {
		return null;
	}
	return u;

	
}
function setloginuser(user) {
	console.log(user,'user');
	localStorage.setItem("loginuser", JSON.stringify(user))
	
}


function delloginall(){
	
	localStorage.clear()
}

function compress(base64String, w, quality) {
	var getMimeType = function(urlData) {
		var arr = urlData.split(',');
		var mime = arr[0].match(/:(.*?);/)[1];
		// return mime.replace("image/", "");
		return mime;
	};
	var newImage = new Image();
	var imgWidth, imgHeight;

	var promise = new Promise(resolve => newImage.onload = resolve);
	newImage.src = base64String;
	return promise.then(() => {
		imgWidth = newImage.width;
		imgHeight = newImage.height;
		var canvas = document.createElement("canvas");
		var ctx = canvas.getContext("2d");
		if (Math.max(imgWidth, imgHeight) > w) {
			if (imgWidth > imgHeight) {
				canvas.width = w;
				canvas.height = w * imgHeight / imgWidth;
			} else {
				canvas.height = w;
				canvas.width = w * imgWidth / imgHeight;
			}
		} else {
			canvas.width = imgWidth;
			canvas.height = imgHeight;
		}
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);
		var base64 = canvas.toDataURL(getMimeType(base64String), quality);
		console.log(base64);
		return base64;
	});
}
/**
 * 导出
 **/
module.exports = {
	genID,
	timestampToTime,
	timestampToDate,
	setStore,
	getStore,
	removeStore,
	setCookie,
	getCookie,
	delCookie,
	getBase64,
	
	setloginuser,
	getloginuser,
	delloginall
}