import Vue from 'vue'
import VueRouter from 'vue-router'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
	//{ path: '*', redirect:'/' },

	//   {
	//     path: '/',
	//     name: '',
	//    component: () => import( /* webpackChunkName: "about" */ '../views/index'),
	//     hidden: true,
	//     meta: {
	//         requireAuth: false
	//     }
	// },

	{
		path: '/',
		name: '默认首页',
		component: () => import( /* webpackChunkName: "about" */ '../views/index'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/published/news/list',
		name: '列表',
		component: () => import( /* webpackChunkName: "about" */ '../views/published/news/list'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/published/news/detail',
		name: '详情',
		component: () => import( /* webpackChunkName: "about" */ '../views/published/news/detail'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/published/qiyewx/qrcode/group',
		name: '群活码',
		component: () => import( /* webpackChunkName: "about" */ '../views/published/qiyewx/qrcode/group'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	}, {
		path: '/published/qiyewx/qrcode/man',
		name: '领券',
		component: () => import( /* webpackChunkName: "about" */ '../views/published/qiyewx/qrcode/man'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/customer/callback/autologin',
		name: '自动登录',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/autologin'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/callback/autoindex',
		name: '自动登录2',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/autoindex'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/callback/autowxwork',
		name: '企微授权',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/autowxwork'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/callback/wxworklogin',
		name: '企微自动登录',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/wxworklogin'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/callback/wxwork',
		name: '企微授权回调',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/wxwork'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/callback/wxxcx',
		name: '授权回调页面',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/wxxcx'),
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/customer/callback/wxworkauthcode',
		name: '企微授权22',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/wxworkauthcode'),
		meta: {
			requireAuth: false
		}
	},
	
	{
		path: '/customer/xcxtemplate/marketlist',
		name: '热门模版',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/marketlist'),
		meta: {
			requireAuth: true
		}
	},

	{
		path: '/customer/xcxtemplate/marketdetail',
		name: '小程序模板市场详情(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/marketdetail'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/callback/wxgzh',
		name: '授权回调',
		component: () => import( /* webpackChunkName: "callopen" */
			'../views/customer/callback/wxgzh'),
		meta: {
			requireAuth: true
		}
	},
	//商户管理管理============================
	{
		path: '/customer/login',
		name: '商户登录',
		component: () => import('../views/customer/login'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},



	{
		path: '/customer/skip',
		name: '跳转',
		component: () => import( /* webpackChunkName: "about" */ '../views/customer/skip'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/manage/app',
		name: '应用管理',
		component: () => import( /* webpackChunkName: "about" */ '../views/customer/manage/app'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/index',
		name: '小程序模板设计',
		component: () => import( /* webpackChunkName: "about" */ '../views/customer/xcxtemplate/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/xcxtemplate/design',
		name: '用户小程序模板1',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/design'),
		meta: {
			requireAuth: true
		},

	},

	{
		path: '/customer/xcxtemplate/mytemplata',
		name: '我的模板',
		component: () => import( /* webpackChunkName: "about" */ '../views/customer/xcxtemplate/mytemplata'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/xcxtemplate/templatebase',
		name: '小程序模板库(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/templatebase'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/tempfine',
		name: '精品模版',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/tempfine'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/marketindex',
		name: '模板市场首页',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/marketindex'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/marketindex',
		name: '模板市场首页2',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/marketindex'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/market',
		name: '小程序模板市场',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/market'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/order/temporderdetail',
		name: 'temporderdetail',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/order/temporderdetail'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/mytempdetail',
		name: 'mytempdetail',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/mytempdetail'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/order/temporderlist',
		name: 'temporderlist',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/order/temporderlist'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/list',
		name: '用户小程序模板2',
		component: () => import( /* webpackChunkName: "about" */ '../views/customer/xcxtemplate/list'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/setting',
		name: '设置(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/setting'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/xcxtemplate/tabbar',
		name: '菜单设置(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/tabbar'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/xcxtemplate/style',
		name: '风格(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/style'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/xcxtemplate/designdemo',
		name: '用户小程序模板Demo',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/xcxtemplate/designdemo'),
		meta: {
			requireAuth: true
		}
	},
	//微信小程序
	{
		path: '/customer/home/mp/weixinapp/alls/index',
		name: '总览3',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/alls/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinapp/appconfig/index',
		name: '小程序配置1',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/appconfig/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinapp/depconfig/index',
		name: '开发配置1',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/depconfig/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinapp/upapp/index',
		name: '上传小程序',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/upapp/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinapp/release/index',
		name: '发布小程序',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/release/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinapp/release/submitapp',
		name: '提交审核',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/release/submitapp'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinapp/privacy/index',
		name: '隐私协议',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/privacy/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinapp/ewmapp/index',
		name: '二维码管理2',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/ewmapp/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinapp/experients/index',
		name: '体验者',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/experients/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinapp/shortlink/index',
		name: '链接',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinapp/shortlink/index'),
		meta: {
			requireAuth: true
		}
	},
	//企业微信
	{
		path: '/customer/home/mp/weixinqiye/alls/index',
		name: '总览2',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/alls/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/person/index',
		name: '人员管理',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/person/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/set/index',
		name: '开发设置(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/set/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/appsys/index',
		name: '应用管理(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/appsys/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/addconfig/index',
		name: '群聊配置',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/addconfig/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/imgmenu/imgmenulist',
		name: '图文列表',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/imgmenu/imgmenulist'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/imgmenu/imgmenu',
		name: '图文详情',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/imgmenu/imgmenu'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/msg/index',
		name: '消息配置',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/msg/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixinqiye/chat/index',
		name: '机器人配置',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/chat/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/license/index',
		name: '企业微信应用授权',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/license/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/scancode/index',
		name: '点餐加好友',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/scancode/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/scancode/details',
		name: '客户详情',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/scancode/details'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/group/group-code',
		name: '客户管理',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/group/group-code'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/group/group-detail',
		name: '群管理',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/group/group-detail'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/qrcode/index',
		name: '二维码管理(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/qrcode/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/qrcode/details',
		name: '二维码详情',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/qrcode/details'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/qrcode/list',
		name: '群活码管理',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/qrcode/list'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/qrcode/info',
		name: '群活码详情',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/qrcode/info'),
		meta: {
			requireAuth: true
		}
	},

	{
		path: '/customer/home/mp/weixinqiye/deskqrcode/index',
		name: '桌台码详情',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/deskqrcode/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/resource/index',
		name: '资源文件',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/resource/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/qwqrcode/index',
		name: '企微桌台码',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/qwqrcode/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/deskqrcode/detail',
		name: '桌台码新增',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/deskqrcode/detail'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/welcome/index',
		name: '入群欢迎语',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/welcome/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/welcome/details',
		name: '入群欢迎语详情',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/welcome/details'),
		meta: {
			requireAuth: true
		}
	},

	//微信公众号
	{
		path: '/customer/home/mp/weixingzh/alls/index',
		name: '总览1',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/alls/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixinqiye/alls/regskip',
		name: '企微注册引导',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixinqiye/alls/regskip'),
		meta: {
			requireAuth: true
		}
	},

	{
		path: '/customer/home/mp/weixingzh/set/index',
		name: '开发设置',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/set/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixingzh/custom/index',
		name: '自定义菜单',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/custom/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixingzh/imgmenu/imgmenu',
		name: '图文',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/imgmenu/imgmenu'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixingzh/imgmenu/imgmenulist',
		name: '图文列表(用户)',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/imgmenu/imgmenulist'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/base/scgzmsg',
		name: '首次关注消息',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/base/scgzmsg'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixingzh/msg/wppmsg',
		name: '无匹配消息',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/msg/wppmsg'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixingzh/template/index',
		name: '模板消息',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/template/index'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/mp/weixingzh/card/index',
		name: '微信会员卡',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/card/index'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixingzh/card/detail',
		name: '微信会员卡详情',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/card/detail'),
		meta: {
			requireAuth: true
		}
	}, {
		path: '/customer/home/mp/weixingzh/card/addcard',
		name: '微信会员卡新增',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/weixingzh/card/addcard'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/customer/home/keyword/index',
		name: '关键词',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/keyword/index'),
		meta: {
			requireAuth: true
		}
	},








	{
		path: '/customer/home/mp/zhifubaoapp/home',
		name: '注册小程序',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customer/home/mp/zhifubaoapp/home'),
		redirect: '/customer/home/mp/zhifubaoapp/alls/index',
		meta: {
			requireAuth: true
		},
		children: [{
				path: '/customer/home/mp/zhifubaoapp/alls/index',
				name: '总览4',
				component: () => import( /* webpackChunkName: "about" */
					'../views/customer/home/mp/zhifubaoapp/alls/index'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/customer/home/mp/zhifubaoapp/appconfig/index',
				name: '小程序配置2',
				component: () => import( /* webpackChunkName: "about" */
					'../views/customer/home/mp/zhifubaoapp/appconfig/index'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/customer/home/mp/zhifubaoapp/depconfig/index',
				name: '开发配置2',
				component: () => import( /* webpackChunkName: "about" */
					'../views/customer/home/mp/zhifubaoapp/depconfig/index'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/customer/home/mp/zhifubaoapp/upapp/index',
				name: '发布小程序(用户)',
				component: () => import( /* webpackChunkName: "about" */
					'../views/customer/home/mp/zhifubaoapp/upapp/index'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/customer/home/mp/zhifubaoapp/ewmapp/index',
				name: '二维码管理',
				component: () => import( /* webpackChunkName: "about" */
					'../views/customer/home/mp/zhifubaoapp/ewmapp/index'),
				meta: {
					requireAuth: true
				}
			}

		]
	},


	// {
	// 	path: '/customer/index',
	// 	name: '商户管理首页',
	// 	meta: {
	// 		requireAuth: true
	// 	},

	// 	component: () => import('../views/customer/index'),
	// 	iconCls: 'el-icon-tickets',
	// 	redirect: '/customer/manage/app',
	// 	children: []
	// },
	//系统管理============================
	{
		path: '/system/login',
		name: '系统登录',
		component: () => import('../views/system/login'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	
	{
		path: '/system/index',
		name: '系统管理首页',
		meta: {
			requireAuth: true
		},
		redirect: '/system/design/tempsys/tempsys',
		component: () => import('../views/system/index'),
		iconCls: 'el-icon-tickets',
		children: [{
				path: '/system/design/tempsys/tempsys',
				name: '已上架模板管理',
				component: () => import( /* webpackChunkName: "about" */
					'../views/system/design/tempsys/tempsys'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/system/design/tempsys/tempsyscheck',
				name: '待审核模板管理',
				component: () => import( /* webpackChunkName: "about" */
					'../views/system/design/tempsys/tempsyscheck'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/system/design/tempsys/tempsysdesign',
				name: '设计中模板管理',
				component: () => import( /* webpackChunkName: "about" */
					'../views/system/design/tempsys/tempsysdesign'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/system/design/tempsys/tempsyscls',
				name: '系统模板分类',
				component: () => import( /* webpackChunkName: "about" */
					'../views/system/design/tempsys/tempsyscls'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/system/design/tempuser/tempuser',
				name: '用户模板管理',
				component: () => import( /* webpackChunkName: "about" */
					'../views/system/design/tempuser/tempuser'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/system/design/component/seticon',
				name: '图标管理',
				component: () => import( /* webpackChunkName: "about" */
					'../views/system/design/component/seticon'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/system/mp/wxwork/list',
				name: '企微查询',
				component: () => import( /* webpackChunkName: "about" */ '../views/system/mp/wxwork/list'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/system/mp/wxwork/license',
				name: '系统端企微授权',
				component: () => import( /* webpackChunkName: "about" */ '../views/system/mp/wxwork/license'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/system/mp/wxwork/orderlist',
				name: '订单管理',
				component: () => import( /* webpackChunkName: "about" */ '../views/system/mp/wxwork/orderlist'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/system/mp/wxwork/logs',
				name: '日志查询',
				component: () => import( /* webpackChunkName: "about" */ '../views/system/mp/wxwork/logs'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/system/sys/user',
				name: '系统用户',
				component: () => import( /* webpackChunkName: "about" */ '../views/system/sys/user'),
				meta: {
					requireAuth: true
				}
			},
			
			{
				path: '/system/design/style/style',
				name: '风格管理',
				component: () => import( /* webpackChunkName: "about" */ '../views/system/design/style/style'),
				meta: {
					requireAuth: true
				}
			},
			
		]
	},

	//设计师端============================
	{
		path: '/designer/login',
		name: '设计师登录',
		component: () => import('../views/designer/login'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/designer/xcxtemplate/design',
		name: '用户小程序模板3',
		component: () => import( /* webpackChunkName: "about" */
			'../views/designer/xcxtemplate/design'),
		meta: {
			requireAuth: true
		},

	},
	{
		path: '/designer/index',
		name: '设计师首页',
		meta: {
			requireAuth: true
		},
		redirect: '/designer/xcxtemplate/index',
		component: () => import('../views/designer/index'),
		iconCls: 'el-icon-tickets',
		children: [{
				path: '/designer/xcxtemplate/index',
				name: '小程序模板设计（设计师）',
				component: () => import( /* webpackChunkName: "about" */ '../views/designer/xcxtemplate/index'),
				meta: {
					requireAuth: true
				}
			},

			{
				path: '/designer/xcxtemplate/templatebase',
				name: '小程序模板库(设计师)',
				component: () => import( /* webpackChunkName: "about" */
					'../views/designer/xcxtemplate/templatebase'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/designer/xcxtemplate/market',
				name: '小程序模板市场2',
				component: () => import( /* webpackChunkName: "about" */
					'../views/designer/xcxtemplate/market'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/designer/xcxtemplate/list',
				name: '用户小程序模板4',
				component: () => import( /* webpackChunkName: "about" */ '../views/designer/xcxtemplate/list'),
				meta: {
					requireAuth: true
				}
			}, {
				path: '/designer/xcxtemplate/setting',
				name: '设置(设计师)',
				component: () => import( /* webpackChunkName: "about" */
					'../views/designer/xcxtemplate/setting'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/designer/xcxtemplate/tabbar',
				name: '菜单设置',
				component: () => import( /* webpackChunkName: "about" */
					'../views/designer/xcxtemplate/tabbar'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/designer/xcxtemplate/style',
				name: '风格(设计师)',
				component: () => import( /* webpackChunkName: "about" */
					'../views/designer/xcxtemplate/style'),
				meta: {
					requireAuth: true
				}
			}
		]
	},
	//app端============================
	{
		path: '/app/qiyewx/login',
		name: 'app登录',
		component: () => import('../views/app/qiyewx/login'),
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/app/qiyewx/index',
		name: '首页',
		meta: {
			requireAuth: true
		},
		redirect: '/app/qiyewx/index',
		component: () => import('../views/app/qiyewx/index'),
		iconCls: 'el-icon-tickets',
		children: [{
			path: '/app/qiyewx/index',
			name: 'app首页',
			component: () => import( /* webpackChunkName: "about" */ '../views/app/qiyewx/index'),
			meta: {
				requireAuth: true
			}
		}, ]
	},
	{
		path: '/app/qiyewx/list/list',
		name: '列表2',
		component: () => import( /* webpackChunkName: "about" */ '../views/app/qiyewx/list/list'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/app/qiyewx/list/info',
		name: '详情2',
		component: () => import( /* webpackChunkName: "about" */ '../views/app/qiyewx/list/info'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/app/qiyewx/control',
		name: '控制台',
		component: () => import( /* webpackChunkName: "about" */ '../views/app/qiyewx/control'),
		meta: {
			requireAuth: true
		}
	},
	{
		path: '/app/qiyewx/my',
		name: '我的',
		component: () => import( /* webpackChunkName: "about" */ '../views/app/qiyewx/my'),
		meta: {
			requireAuth: true
		}
	},

]
/**
 * history 模式 nginx配置：
 * location/{
        try_files $uri $uri/ /index.html;
}
 */
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router