// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
// 引入element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App';
// 引入路由
import router from './router';
// 引入状态管理
import store from './vuex/store';
// 引入icon
import './assets/icon/iconfont.css'

import NProgress from 'nprogress' // 进度条；进度指示器
import 'nprogress/nprogress.css' // 进度条；进度指示器 样式

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import axios from 'axios';
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

//忽略Vue对开放标签的检查
// Vue.config.ignoredElements = ['wx-open-launch-weapp'];
Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp'];

// 使用element UI
Vue.use(ElementUI, { size: 'mini', zIndex: 3000 });
// 过滤器
import * as custom from './utils/util'

Object.keys(custom).forEach(key => {
	Vue.filter(key, custom[key])
})
//清空打印
// if (window) {
// 	window.console.log = function() {};
// }
//配置插件
NProgress.configure({
	easing: 'ease', // 动画方式
	speed: 500, // 递增进度条的速度
	showSpinner: false, // 是否显示加载ico
	trickleSpeed: 200, // 自动递增间隔
	minimum: 0.3 // 初始化时的最小百分比
})
//跳转结束了
router.afterEach(() => {
	// 在即将进入新的页面组件前，关闭掉进度条
	NProgress.done()
})

/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	store, //使用store vuex状态管理
	components: {
		App
	},
	template: '<App/>',
	data: {
		// 空的实例放到根组件下，所有的子组件都能调用
		Bus: new Vue()
	}

})